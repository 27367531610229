import Person from "components/people/Person";
import { getPeople } from "features/people/actions";
import { usePerson } from "features/people/reducer";
import { getRole, Role, useRole } from "features/roles";
import { getTenures, Tenure, useTenures } from "features/tenures";
import { Slug } from "helpers/types";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch } from "store/hooks";

type Props = {
  slug: Slug<Role>;
};

function RolePage({ match }: RouteComponentProps<Props>) {
  const dispatch = useAppDispatch();

  const { slug } = match.params;

  useEffect(() => {
    dispatch(getRole(slug));
    dispatch(getTenures(slug));
    dispatch(getPeople());
  }, [dispatch, slug]);

  const role = useRole(slug);
  const tenures = useTenures(slug);

  if (!role || !tenures) {
    return null;
  }

  const { name } = role;

  return (
    <div>
      <h1>{name}</h1>
      {tenures.map((tenure) => (
        <TenureBox tenure={tenure} key={tenure.id} />
      ))}
    </div>
  );
}

type TenureProps = {
  tenure: Tenure;
};

function TenureBox({ tenure }: TenureProps) {
  const person = usePerson(tenure.person.slug);

  if (!person) {
    return null;
  }

  return (
    <Person key={tenure.id} person={person}>
      {tenure.year} &middot; {person.schoolYearName}
    </Person>
  );
}

export default RolePage;
