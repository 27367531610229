import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "api";
import { resetPasswordForEmail } from "features/authentication/api";
import { Email } from "features/authentication/types";
import {
  CreatePersonParams,
  Person,
  UpdatePersonParams,
} from "features/people/types";
import { UploadImageParams } from "helpers/types";

export const getPeople = createAsyncThunk<Array<Person>>(
  "people/getPeople",
  async () => {
    return await get<Array<Person>>("/people");
  }
);

export const getPeopleList = createAsyncThunk<Array<Person>>(
  "people/getPeopleList",
  async () => {
    return get<Array<Person>>("/people/list");
  }
);

export const getPerson = createAsyncThunk<Person, string>(
  "people/getPerson",
  async (slug: string) => {
    return await get<Person>(`/people/${slug}`);
  }
);

export const createPerson = createAsyncThunk<Person, CreatePersonParams>(
  "people/createPerson",
  async ({ email, ...params }: CreatePersonParams) => {
    const userAttributes = {
      email,
    };
    return await post<Person>("/people", {
      person: {
        userAttributes,
        ...params,
      },
    });
  }
);

export const updatePerson = createAsyncThunk<Person, UpdatePersonParams>(
  "people/updatePerson",
  async (params: UpdatePersonParams) => {
    const {
      addressId,
      street,
      zipCode,
      city,
      state,
      country,
      userId,
      email,
      password,
      ...rest
    } = params;

    const addressParams = {
      id: addressId,
      street,
      zipCode,
      city,
      state,
      country,
    };
    const userParams = {
      id: userId,
      email,
      password,
    };

    const body = {
      addressAttributes: addressParams,
      userAttributes: userParams,
      ...rest,
    };

    return await put<Person>(`/people/${params.slug}`, body);
  }
);

export const updateAvatar = createAsyncThunk<Person, UploadImageParams>(
  "people/updateAvatar",
  async ({ entityId, image }: UploadImageParams) => {
    const body = {
      person: {
        avatar: image,
      },
    };
    return await put<Person>(`/people/${entityId}`, body);
  }
);

export const resetPassword = createAsyncThunk<void, { email: Email }>(
  "people/resetPassword",
  async ({ email }: { email: Email }) => {
    return await resetPasswordForEmail(email);
  }
);

// TODO: rewrite address export with client-side CSV generation
// export const getAddresses = createAsyncThunk<Array<PersonWithAddress>, void>(
//   "people/exportAddresses",
//   async () => {
//     return await get<Array<PersonWithAddress>>("/people/addresses");
//   }
// );
