import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

import { authenticate, logout } from "./reducer";
import { Credentials } from "./types";

export function useAuthenticate() {
  const dispatch = useAppDispatch();
  return (credentials: Credentials) => {
    return dispatch(authenticate(credentials));
  };
}

export function useAuthentication() {
  return useAppSelector((state) => state.authentication.authenticated);
}

export function useLogout() {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(logout());
  };
}

export function selectAuthenticated(state: RootState) {
  return state.authentication.authenticated;
}

export function selectSlug(state: RootState) {
  return state.authentication.authenticated && state.authentication.slug;
}
