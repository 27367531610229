import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  addMember,
  createTeam,
  getTeam,
  getTeams,
  updateCover,
} from "features/teams/actions";
import { Team } from "features/teams/types";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export const teamAdapter = createEntityAdapter<Team>({
  selectId: (team: Team) => team.year,
  sortComparer: (a: Team, b: Team) => b.year - a.year,
});

export const teamSlice = createSlice({
  name: "teams",
  initialState: teamAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeam.fulfilled, teamAdapter.setOne);
    builder.addCase(getTeams.fulfilled, teamAdapter.setAll);
    builder.addCase(createTeam.fulfilled, teamAdapter.addOne);
    builder.addCase(addMember.fulfilled, teamAdapter.upsertOne);
    builder.addCase(updateCover.fulfilled, teamAdapter.upsertOne);
  },
});

export default teamSlice.reducer;

export const { selectAll: selectAllTeams, selectById: selectTeam } =
  teamAdapter.getSelectors((state: RootState) => state.teams);

export function useTeam(year: number) {
  return useAppSelector((state) => selectTeam(state, year));
}

export function useTeams() {
  return useAppSelector(selectAllTeams);
}
