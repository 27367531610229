import { Link } from "components/common";
import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";

type Props = {
  title: string;
  subtitle?: string;
  url?: string;
  children: ReactNode;
  primary?: boolean;
};

const BoxWrapper = styled.div`
  background: ${(props) => props.theme.lightBackgroundColor};
  padding: 1rem;
  margin: 0.25rem;
`;

const Header = styled.header`
  color: ${(props) => props.theme.primaryTextColor};
  margin: 0;
`;

const Heading = styled.h4<{ primary: boolean }>`
  color: ${(props) =>
    props.primary ? props.theme.accentTextColor : props.theme.primaryTextColor};
  margin: 0;
`;

const Data = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
`;

export function Box({
  title,
  subtitle,
  url,
  children,
  primary = false,
}: Props) {
  return (
    <BoxWrapper>
      <Url url={url}>
        <Header>
          <Heading primary={primary}>
            {title}
            {subtitle && <span> &middot; {subtitle}</span>}
          </Heading>
        </Header>
        <Data>{children}</Data>
      </Url>
    </BoxWrapper>
  );
}

type UrlProps = {
  url?: string;
  children: ReactNode;
};

function Url({ url, children }: UrlProps) {
  if (url) {
    return <Link to={url}>{children}</Link>;
  } else {
    return <Fragment>{children}</Fragment>;
  }
}
