import { Box, ButtonLink, Table, TableRowData } from "components/common";
import PersonDisplayList, {
  PersonDisplayProps
} from "components/people/PersonDisplay";
import Portrait from "components/people/Portrait";
import { getMemberships, useMemberships } from "features/memberships";
import { getPerson } from "features/people/actions";
import { useIsAdmin, useIsSelf, usePerson } from "features/people/reducer";
import { Person, User } from "features/people/types";
import { Slug } from "helpers/types";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import styled from "styled-components";

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileInfoWrapper = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.h2`
  margin: 0;
`;

const Year = styled.h3`
  margin: 0 0 1rem;
`;

type Props = {
  slug: Slug<Person>;
};

function Profile({ match }: RouteComponentProps<Props>) {
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();

  const { slug } = match.params;

  useEffect(() => {
    dispatch(getPerson(slug));
    dispatch(getMemberships(slug));
  }, [dispatch, slug]);

  const person = usePerson(slug);
  const isSelf = useIsSelf(slug);
  const memberships = useMemberships(slug);

  if (!person || !memberships) {
    return null;
  }

  const drifters: Array<PersonDisplayProps> = memberships.map(
    ({ id, title, year, portrait, person }) => {
      return {
        id,
        title,
        person,
        subtitle: String(year),
        url: `/drifveri/${year}`,
        image: portrait,
      };
    }
  );

  return (
    <ProfileWrapper>
      <ProfileInfoWrapper>
        <Portrait image={person.avatar} />
        <ProfileInfo>
          <Name>{person.name}</Name>
          <Year>{person.schoolYearName}</Year>
          {(isSelf || isAdmin) && <UserDetails user={person.user} />}

          {isSelf && (
            <ButtonLink to="/profile/edit">Redigera profil</ButtonLink>
          )}
        </ProfileInfo>
      </ProfileInfoWrapper>

      <PersonDisplayList title="Drifvarhistorik" people={drifters} />
    </ProfileWrapper>
  );
}

type UserDetailsProps = {
  user?: User
}

function UserDetails({ user }: UserDetailsProps) {
  if (!user) {
    return <EmptyUserDetails />;
  }

  const { email } = user
  const rows: Array<TableRowData> = [{
    title: 'Email',
    data: [email]
  }];

  return (
    <Box title="Användare">
      <Table rows={rows} />
    </Box>
  );
}

function EmptyUserDetails() {
  return (
    <Box title="Användare">
      <span>Den här drifvaren har inget användarkonto</span>
    </Box>
  );
}



export default Profile;
