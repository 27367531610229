import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background: #f9f9fa;
  color: #ee2a7b;
  border: none;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  width: 100%;
  font-size: 1rem;
  font-family: inherit;

  &:hover {
    cursor: pointer;
  }
`;

type ButtonProps = {
  type?: "button" | "submit";
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

export function Button({ type = "button", children, ...props }: ButtonProps) {
  return (
    <StyledButton type={type} {...props}>
      {children}
    </StyledButton>
  );
}
