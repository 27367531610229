import { Button } from "components/common";
import { Email } from "features/authentication/types";
import { resetPassword } from "features/people/actions";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React from "react";
import { useAppDispatch } from "store/hooks";
import css from "styles/forms/form.module.css";

const INCORRECT_EMAIL_MESSAGE = "Det finns inget konto med den adressen";

enum PasswordResetStatus {
  SUCCESS,
  FAILURE,
  NOT_ATTEMPTED,
}

type PasswordResetFormProps = {
  email: Email;
};

function PasswordResetContainer() {
  const dispatch = useAppDispatch();
  const handleSubmit = (
    params: PasswordResetFormProps,
    {
      setSubmitting,
      setStatus,
      setFieldError,
    }: FormikHelpers<PasswordResetFormProps>
  ) => {
    dispatch(resetPassword(params))
      .unwrap()
      .then(() => {
        console.log("Succeeding when resetting password");
        setStatus(PasswordResetStatus.SUCCESS);
      })
      .catch((error) => {
        console.error("Error when resetting password", error);
        setStatus(PasswordResetStatus.FAILURE);
        setFieldError("email", INCORRECT_EMAIL_MESSAGE);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      initialStatus={PasswordResetStatus.NOT_ATTEMPTED}
      onSubmit={handleSubmit}
      component={PasswordResetForm}
    />
  );
}

function PasswordResetForm({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  status,
}: FormikProps<PasswordResetFormProps>) {
  return (
    <Form>
      <div className={css.wrapper}>
        <label>Email</label>
        <Field type="email" name="email" />
        <ErrorMessage name="email">
          {(msg) => <div className={css.error}>{msg}</div>}
        </ErrorMessage>
      </div>
      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        {status === PasswordResetStatus.NOT_ATTEMPTED && (
          <span>Återställ lösenord</span>
        )}
        {status === PasswordResetStatus.FAILURE && (
          <span>Återställ lösenord</span>
        )}
        {status === PasswordResetStatus.SUCCESS && (
          <span>Ditt lösenord är återställt!</span>
        )}
      </Button>
    </Form>
  );
}

export default PasswordResetContainer;
