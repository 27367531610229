import { Button } from "components/common";
import Select from "components/common/Select";
import { createTeam } from "features/teams/actions";
import { Field, Form, Formik } from "formik";
import { range } from "helpers/utils";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import css from "styles/forms/form.module.css";

const today = new Date().getFullYear();
const years = range(1983, today)
  .sort()
  .reverse()
  .map((y) => ({ value: y, label: y }));

class TeamFormContainer extends Component {
  handleSubmit = (values, actions) => {
    const params = {
      ...values,
      year: values.year.value,
    };
    this.props.createTeam(params).then(() => {
      actions.setSubmitting(false);
    });
  };

  prefilled = () => {
    return {
      name: `Drifveriet ${today}`,
      year: { value: today, label: today },
    };
  };

  render = () => {
    const values = this.prefilled();

    return (
      <div className={css.form}>
        <Formik
          initialValues={values}
          onSubmit={this.handleSubmit}
          component={TeamForm}
        />
      </div>
    );
  };
}

const mapState = (state, props) => {
  return {};
};

const mapDispatch = (dispatch) => bindActionCreators({ createTeam }, dispatch);

const TeamForm = (props) => {
  const { values, isSubmitting, setFieldValue, handleBlur } = props;

  return (
    <Form>
      <Field hidden name="id" />

      <div className={css.wrapper}>
        <label>Namn</label>
        <Field name="name" value={values.name} onChange={setFieldValue} />
      </div>

      <label>År</label>
      <Field
        name="year"
        component={Select}
        options={years}
        value={values.year}
        onBlur={handleBlur}
        onChange={setFieldValue}
      />

      <Button type="submit" disabled={isSubmitting}>
        Skapa Drifveri
      </Button>
    </Form>
  );
};

export default connect(mapState, mapDispatch)(TeamFormContainer);
