import { Button } from "components/common";
import Select from "components/common/Select";
import {
  createEvent,
  editEvent,
  getEvent,
  selectEventById,
} from "features/events/reducer";
import { useCurrentPerson } from "features/people/reducer";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { range } from "helpers/utils";
import moment from "moment";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import css from "styles/forms/form.module.css";

const today = new Date().getFullYear();

const years: Array<YearOption> = range(today - 3, today + 3)
  .sort()
  .reverse()
  .map((y) => ({ value: y, label: y }));

type Props = {
  year: string;
};

type EventFormValues = {
  id: number; // id of person creating event
  name: string;
  description: string;
  year: YearOption;
  // signupDeadline: Date;
  signupDeadline: string;
  // date: Date;
  date: string;
  // time: Date;
  time: string;
  studentPrice: number;
  alumniPrice: number;
};

type YearOption = {
  value: number;
  label: number;
};

export function EditEventPage({ match }: RouteComponentProps<Props>) {
  const { year } = match.params;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (year) {
      dispatch(getEvent(parseInt(year)));
    }
  }, [dispatch, year]);

  const event = useAppSelector((state) => selectEventById(state, year));

  if (!event) {
    return null;
  }

  const handleSubmit = (
    values: EventFormValues,
    actions: FormikHelpers<any>
  ) => {
    const params = {
      ...values,
      year: values.year.value,
      when: moment(`${values.date} ${values.time}`).toDate(),
      signupDeadline: moment(`${values.signupDeadline} 23:59`).toDate(),
    };
    dispatch(editEvent(params)).then(() => {
      actions.setSubmitting(false);
    });
  };

  const initialValues = {
    ...event,
    year: { value: event.year, label: event.year },
    date: moment(event.when).format("YYYY-MM-DD"),
    time: moment(event.when).format("HH:mm"),
    signupDeadline: moment(event.signupDeadline).format("YYYY-MM-DD"),
    studentPrice: event.studentPrice || 0,
    alumniPrice: event.alumniPrice || 0,
  };

  return (
    <div className={css.form}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        component={EventForm}
      />
    </div>
  );
}

export function CreateEventPage() {
  const dispatch = useAppDispatch();

  const handleSubmit = (
    values: EventFormValues,
    actions: FormikHelpers<any>
  ) => {
    const params = {
      ...values,
      year: values.year.value,
      when: moment(`${values.date} ${values.time}`).toDate(),
      signupDeadline: moment(`${values.signupDeadline} 23:59`).toDate(),
    };
    dispatch(createEvent(params)).then(() => {
      actions.setSubmitting(false);
    });
  };

  const person = useCurrentPerson();

  if (!person) {
    return null;
  }

  const initialValues: EventFormValues = {
    id: person.id,
    name: `KDE ${today}`,
    description: "",
    year: years[3],
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm"),
    signupDeadline: moment().format("YYYY-MM-DD"),
    alumniPrice: 0,
    studentPrice: 0,
  };

  return (
    <div className={css.form}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        component={EventForm}
      />
    </div>
  );
}

const EventForm = (props: FormikProps<EventFormValues>) => {
  const { values, isSubmitting, setFieldValue, handleBlur, handleSubmit } =
    props;
  return (
    <Form>
      <Field hidden name="id" />

      <div className={css.wrapper}>
        <label>Namn</label>
        <Field name="name" />
      </div>

      <label>År</label>
      <Field
        name="year"
        component={Select}
        options={years}
        value={values.year}
        onBlur={handleBlur}
        onChange={setFieldValue}
      />

      <div className={css.wrapper}>
        <label>Datum</label>
        <Field name="date" type="date" />
      </div>

      <div className={css.wrapper}>
        <label>Tid</label>
        <Field name="time" type="time" />
      </div>
      <div className={css.wrapper}>
        <label>Anmälan stänger</label>
        <Field name="signupDeadline" type="date" />
      </div>

      <div className={css.wrapper}>
        <label>Beskrivning</label>
        <label>Du kan använda Markdown här!</label>
        <Field name="description" component="textarea" />
      </div>

      <div className={css.wrapper}>
        <label>Pris (student)</label>
        <Field name="studentPrice" type="number" />
      </div>

      <div className={css.wrapper}>
        <label>Pris (alumni)</label>
        <Field name="alumniPrice" type="number" />
      </div>

      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        Spara event
      </Button>
    </Form>
  );
};
