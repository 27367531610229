import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
  margin: 0 auto;
`;

const Portrait = ({ image }) => {
  if (!image) return null;
  const url = image;
  return <Image src={url} />;
};

export default Portrait;
