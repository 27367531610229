import { Link } from "components/common";
import css from "components/header/header.module.css";
import { LogoutButton } from "components/login/LogoutButton";
import { useAuthentication } from "features/authentication/actions";
import { useCurrentPerson } from "features/people/reducer";
import React from "react";

function TopBarNavigation() {
  const authenticated = useAuthentication();

  if (authenticated) {
    return <AuthenticatedNavigation />;
  } else {
    return <UnauthenticatedNavigation />;
  }
}

function AuthenticatedNavigation() {
  const user = useCurrentPerson();
  if (!user) {
    return null;
  }

  const profile = `/drifvare/${user.slug}`;

  return (
    <nav className={css.navigation}>
      <Link to={profile}>{user.firstName}</Link>
      <Link to="/kde/2022">KDE</Link>
      <Link to="/drifvare">Drifvare</Link>
      <Link to="/drifverier">Drifverier</Link>
      <Link to="/drifvare/titel/konglig-ofverdrif">ÖD</Link>
      <Link to="/drifvare/titel/konglig-direktifdrif">DD</Link>
      <Link to="/drifvare/titel/konglig-indrif">ID</Link>
      <LogoutButton />
    </nav>
  );
}

function UnauthenticatedNavigation() {
  return (
    <nav className={css.navigation}>
      <Link to="/login">Logga in</Link>
    </nav>
  );
}

export default TopBarNavigation;
