import "moment/locale/sv";

import moment from "moment";

moment.locale("sv");

export const date = (when, format = "L") => {
  if (!when) return null;
  return moment(when).format(format);
};
