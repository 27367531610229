import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { selectSlug } from "features/authentication/actions";
import {
  createPerson,
  getPeople,
  getPeopleList,
  getPerson,
  updateAvatar,
  updatePerson,
} from "features/people/actions";
import { Person } from "features/people/types";
import { Slug } from "helpers/types";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export const peopleAdapter = createEntityAdapter<Person>({
  selectId: (person) => person.slug,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const peopleSlice = createSlice({
  name: "people",
  initialState: peopleAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPeople.fulfilled, peopleAdapter.setAll);
    builder.addCase(getPeopleList.fulfilled, peopleAdapter.upsertMany);
    builder.addCase(getPerson.fulfilled, peopleAdapter.addOne);
    builder.addCase(createPerson.fulfilled, peopleAdapter.addOne);
    builder.addCase(updatePerson.fulfilled, peopleAdapter.upsertOne);
    builder.addCase(updateAvatar.fulfilled, peopleAdapter.upsertOne);
  },
});

export default peopleSlice.reducer;

export const { selectAll: selectAllPeople, selectById: selectPerson } =
  peopleAdapter.getSelectors((state: RootState) => state.people);

export const selectIsAdmin = (state: RootState) => {
  const slug = selectSlug(state);
  if (!slug) {
    return false;
  }
  const person = selectPerson(state, slug);
  return person?.admin;
};

export function usePeople() {
  return useAppSelector(selectAllPeople);
}

export function usePerson(slug: Slug<Person>) {
  return useAppSelector((state) => selectPerson(state, slug));
}

const selectCurrentPerson = (state: RootState) => {
  const slug = selectSlug(state);
  if (!slug) {
    return undefined;
  }
  return selectPerson(state, slug);
};

export function useCurrentPerson(): Person | undefined {
  return useAppSelector((state) => selectCurrentPerson(state));
}

export const useIsAdmin = () => {
  return useAppSelector(selectIsAdmin);
};

function isSelf(state: RootState, slug: Slug<Person>) {
  const authenticatedUser = selectSlug(state);
  return authenticatedUser === slug;
}

export function useIsSelf(slug: Slug<Person>) {
  return useAppSelector((state) => isSelf(state, slug));
}
