import { Component } from "react";

import Upload from "./Upload";

class ActiveStorageUpload extends Component {
  initialState = {
    uploading: false,
    files: {},
  };
  state = {
    ...this.initialState,
  };

  handleUpload = (files) => {
    if (this.state.uploading) return;
    if (files.length === 0) return;

    this.setState({ uploading: true }, () => {
      Promise.all([...files].map((file) => this.upload(file))).then((ids) => {
        this.props.upload(ids).then((data) => {
          this.setState(this.initialState);
          this.props.onSubmit && this.props.onSubmit(data);
        });
      });
    });
  };

  upload = (file) => {
    const { url } = this.props;

    return new Upload(file, {
      url,
      onChangeFile: (fileUpload) =>
        this.setState(({ files }) => ({ files: { ...files, ...fileUpload } })),
    }).start();
  };

  render() {
    const { files } = this.state;
    return this.props.render({
      handleUpload: this.handleUpload,
      ready: !this.state.uploading,
      uploads: Object.keys(files).map((key) => files[key]),
    });
  }
}

export default ActiveStorageUpload;
