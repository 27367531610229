import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { get, post, put } from "api";
import { Person } from "features/people/types";
import { Reference } from "helpers/types";
import { RootState } from "store/store";

export type Event = {
  id: number;
  name: string;
  year: number;
  when: Date;
  signupDeadline: Date;
  description: string;
  studentPrice: number;
  alumniPrice: number;
  attending: Array<EventResponse>;
  stats: EventStats;
};

export type EventResponse = {
  id: number;
  person: Reference<Person>;
  event: Reference<Event>;
  humanizedPreferences: {
    [key: string]: string;
  };
};

export type FoodStats = {
  [key: string]: number;
};

export type DrinkStats = {
  alcoholic: number;
  nonAlcoholic: number;
};

export type TicketStats = {
  student: number;
  alumni: number;
};

export type EventStats = {
  foodPreferences: FoodStats;
  drinkPreferences: DrinkStats;
  ticketTypes: TicketStats;
};

export const eventAdapter = createEntityAdapter<Event>({
  selectId: (event) => event.year,
  sortComparer: (a, b) => b.year - a.year,
});

export const getEvent = createAsyncThunk<Event, number>(
  "events/getEvent",
  async (year: number) => {
    return await get<Event>(`/events/${year}`);
  }
);

export type CreateEventParams = {
  id: number;
  year: number;
};

export const createEvent = createAsyncThunk<Event, CreateEventParams>(
  "events/createEvent",
  async ({ id, ...params }: CreateEventParams) => {
    return await post<Event>("/events", {
      event: {
        personId: id,
        ...params,
      },
    });
  }
);

type UpdateEventParams = {
  id: number;
  year: number;
};

export const editEvent = createAsyncThunk<Event, UpdateEventParams>(
  "events/editEvent",
  async ({ id, year, ...params }: UpdateEventParams) => {
    return await put<Event>(`/events/${year}`, {
      event: {
        personId: id,
        ...params,
      },
    });
  }
);

export type CreateEventResponseParams = {
  eventId: number;
  person: number;
  vegan: boolean;
  vegetarian: boolean;
  lactose: boolean;
  gluten: boolean;
  foodPreferencesText: string;
};

export const createEventResponse = createAsyncThunk<
  EventResponse,
  CreateEventResponseParams
>(
  "events/createEventResponse",
  async ({
    eventId,
    person,
    vegan,
    vegetarian,
    lactose,
    gluten,
    foodPreferencesText,
    ...params
  }: CreateEventResponseParams) => {
    const foodPreferenceAttributes = {
      vegan,
      vegetarian,
      lactose,
      gluten,
      text: foodPreferencesText,
    };

    return await post<EventResponse>("/event_responses", {
      eventResponse: {
        personId: person,
        eventId,
        foodPreferenceAttributes,
        ...params,
      },
    });
  }
);

export const eventSlice = createSlice({
  name: "events",
  initialState: eventAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvent.fulfilled, eventAdapter.setOne);
    builder.addCase(createEvent.fulfilled, eventAdapter.addOne);
    builder.addCase(editEvent.fulfilled, eventAdapter.upsertOne);
  },
});

export default eventSlice.reducer;

export const { selectAll: selectAllEvents, selectById: selectEventById } =
  eventAdapter.getSelectors((state: RootState) => state.events);
