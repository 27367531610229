import { Button, Link } from "components/common";
import { useAuthenticate } from "features/authentication/actions";
import { Credentials } from "features/authentication/types";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React from "react";
import css from "styles/forms/form.module.css";

const INCORRECT_PASSWORD_MESSAGE = "Felaktiga inloggningsuppgifter";

function LoginFormContainer() {
  const authenticate = useAuthenticate();
  const handleSubmit = (
    credentials: Credentials,
    { setSubmitting, setFieldError }: FormikHelpers<Credentials>
  ) => {
    authenticate(credentials)
      .unwrap()
      .catch((error) => {
        console.log(error);
        setFieldError("password", INCORRECT_PASSWORD_MESSAGE);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={handleSubmit}
      component={LoginForm}
    />
  );
}

const Notes = () => (
  <div>
    <h5>Hade du ett konto på gamla drifveriet.org?</h5>
    <p>
      För att logga in, använd knappen ovan och ange din email från gamla sidan
      för att få ett nytt lösenord till samma email. Du kan därefter logga in
      och ändra ditt lösenord till ett nytt. Om du inte hade någon email inlagd
      på gamla drifveriet.org, kontakta Konglig Öfverdrif på{" "}
      <a href="mailto:ofverdrif@d.kth.se">ofverdrif@d.kth.se</a> för att få
      hjälp.
    </p>
  </div>
);

function LoginForm({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
}: FormikProps<Credentials>) {
  return (
    <Form>
      <div className={css.wrapper}>
        <label>Email</label>
        <Field type="email" name="email" />
      </div>
      <div className={css.wrapper}>
        <label>Password</label>
        <Field type="password" name="password" />
        <ErrorMessage name="password">
          {(msg) => <div className={css.error}>{msg}</div>}
        </ErrorMessage>
      </div>
      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        Logga in
      </Button>

      <Link to="/password-reset">Återställ lösenord</Link>

      <Notes />
    </Form>
  );
}

export default LoginFormContainer;
