import { ButtonLink } from "components/common";
import EventSummary from "components/events/EventSummary";
import PersonBox from "components/people/Person";
import { getEvent, selectEventById } from "features/events/reducer";
import { usePerson } from "features/people/reducer";
import { Person } from "features/people/types";
import { date } from "helpers/date";
import { Reference } from "helpers/types";
import { join } from "helpers/utils";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

import css from "./event.module.css";

const Edit = ({ year }: { year: string }) => {
  return <ButtonLink to={`/kde/${year}/edit`}>Redigera KDE</ButtonLink>;
};

type Props = {
  year: string;
};

// TODO: Refactor into Event header component

const EventAdmin = ({ match }: RouteComponentProps<Props>) => {
  const { year } = match.params;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEvent(parseInt(year)));
  }, [dispatch, year]);

  const event = useAppSelector((state) => selectEventById(state, year));

  if (!event) {
    return null;
  }

  const { name, when, attending, stats } = event;

  const day = date(when, "dddd, Do MMMM");
  const time = date(when, "LT");

  return (
    <div className={css.event}>
      <h1 className={css.title}>{name}</h1>
      <h2 className={css.when}>
        {day} {time}
      </h2>

      <Edit year={year} />

      <EventSummary stats={stats} />

      <h3>Anmälda ({attending.length} st)</h3>
      <div className={css.responses}>
        {attending.length > 0 &&
          attending.map((response) => (
            <Response
              key={response.person.slug}
              person={response.person}
              humanizedPreferences={response.humanizedPreferences}
            />
          ))}
      </div>
      {attending.length === 0 && <p>Ingen har anmält sig än...</p>}
    </div>
  );
};

EventAdmin.defaultProps = {
  event: {
    attending: [],
    stats: {
      foodPreferences: {},
      drinkPreferences: {},
      ticketTypes: {},
    },
  },
};

type EventResponseProps = {
  person: Reference<Person>;
  humanizedPreferences: {
    [key: string]: string;
  };
};

const Response = ({ humanizedPreferences, ...props }: EventResponseProps) => {
  const preferences = Object.values(humanizedPreferences);
  const person = usePerson(props.person.slug);
  if (!person) {
    return null;
  }
  return (
    <PersonBox person={person} withYear>
      {join(preferences, " · ")}
    </PersonBox>
  );
};

export default EventAdmin;
