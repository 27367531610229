import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "api";
import { AddMemberParams, CreateTeamParams, Team } from "features/teams/types";
import { UploadImageParams } from "helpers/types";

export const getTeam = createAsyncThunk<Team, number>(
  "teams/getTeam",
  async (id: number) => {
    return await get<Team>(`/teams/${id}`);
  }
);

export const getTeams = createAsyncThunk<Array<Team>>(
  "teams/getTeams",
  async () => {
    return await get<Array<Team>>("/teams");
  }
);

export const createTeam = createAsyncThunk<Team, CreateTeamParams>(
  "teams/createTeam",
  async (params: CreateTeamParams) => {
    return await post<Team>("/teams", {
      team: params,
    });
  }
);

export const addMember = createAsyncThunk<Team, AddMemberParams>(
  "teams/addMember",
  async ({ person, teamId }: AddMemberParams) => {
    return await post(`/teams/${teamId}/add_member`, {
      team: {
        person,
      },
    });
  }
);

export const updateCover = createAsyncThunk<Team, UploadImageParams>(
  "teams/updateCoverImage",
  async ({ entityId, image }: UploadImageParams) => {
    return await put(`/teams/${entityId}`, {
      team: {
        cover: image,
      },
    });
  }
);
