import { Button } from "components/common";
import Select from "components/common/Select";
import { selectSlug } from "features/authentication/actions";
import { createEventResponse } from "features/events/reducer";
import { Field, Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import css from "styles/forms/form.module.css";

const responses = [
  { value: 0, label: "Jag kommer tyvärr inte!" },
  { value: 1, label: "Jag kommer!" },
];

const drinkPreferences = [
  { value: 0, label: "Alkoholfritt" },
  { value: 1, label: "Alkohol" },
];

const ticketTypes = [
  { value: 0, label: "Alumni" },
  { value: 1, label: "Student" },
];

class EventResponseForm extends Component {
  handleSubmit = (values, actions) => {
    const params = {
      ...values,
      response: values.response.value,
      drinkPreferences: values.drinkPreferences.value,
      ticketType: values.ticketType.value,
    };
    this.props.createEventResponse(params).then(() => {
      actions.setSubmitting(false);
    });
  };
  render = () => {
    const { person, event } = this.props;
    const prefilled = {
      person,
      eventId: event,
      response: responses[1],
      drinkPreferences: drinkPreferences[1],
      ticketType: ticketTypes[0],
    };
    return (
      <div className={css.form}>
        <Formik
          initialValues={prefilled}
          onSubmit={this.handleSubmit}
          component={EventForm}
        />
      </div>
    );
  };
}

const mapState = (state) => ({
  person: selectSlug(state),
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ createEventResponse }, dispatch);

const EventForm = (props) => {
  const { values, isSubmitting, setFieldValue, handleBlur } = props;
  return (
    <Form>
      <Field hidden name="eventId" />
      <Field hidden name="id" />

      <div className={css.wrapper}>
        <Field
          name="response"
          component={Select}
          options={responses}
          label="Anmälan"
          value={values.response}
          onBlur={handleBlur}
          onChange={setFieldValue}
        />
      </div>
      {values.response.value === 1 && (
        <Fragment>
          <div className={css.wrapper}>
            <Field
              name="drinkPreferences"
              component={Select}
              options={drinkPreferences}
              label="Dryck"
              value={values.drinkPreferences}
              onBlur={handleBlur}
              onChange={setFieldValue}
            />
          </div>
          <div className={css.wrapper}>
            <Field
              name="ticketType"
              component={Select}
              options={ticketTypes}
              label="Biljett"
              value={values.ticketType}
              onBlur={handleBlur}
              onChange={setFieldValue}
            />
          </div>

          <h4 className={css.header}>Matpreferenser</h4>

          <label className={css.checkboxLabel}>
            <Field name="vegan" type="checkbox" /> Vegan
          </label>

          <label className={css.checkboxLabel}>
            <Field name="vegetarian" type="checkbox" /> Vegetarian
          </label>

          <label className={css.checkboxLabel}>
            <Field name="lactose" type="checkbox" /> Laktosintolerant
          </label>

          <label className={css.checkboxLabel}>
            <Field name="gluten" type="checkbox" /> Glutenintolerant
          </label>

          <div className={css.wrapper}>
            <label>Övriga matpreferenser</label>
            <Field name="foodPreferencesText" component="textarea" />
          </div>

          <div className={css.wrapper}>
            <label>Kommentar</label>
            <Field name="comment" component="textarea" />
          </div>
        </Fragment>
      )}

      <Button type="submit" disabled={isSubmitting}>
        Skicka svar
      </Button>
    </Form>
  );
};

export default connect(mapState, mapDispatch)(EventResponseForm);
