import Navigation from "components/header/Navigation";
import { selectAuthenticated } from "features/authentication/actions";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import css from "./header.module.css";

const Header = ({ authenticated }) => {
  if (!authenticated) {
    return null;
  }
  return (
    <div className={css.header}>
      <Logo />
      <Navigation />
    </div>
  );
};

export const Logo = () => (
  <div className={css.logo}>
    <Link to="/">drifveriet.org</Link>
  </div>
);

const mapState = (state) => {
  return {
    authenticated: selectAuthenticated(state),
  };
};

export default connect(mapState)(Header);
