import { selectAuthenticated } from "features/authentication/actions";
import React from "react";
import { connect } from "react-redux";
import { AuthRoute, UnauthRoute } from "react-router-auth";
import { Route } from "react-router-dom";

const ConnectedRoute = ({ auth, noauth, ...props }) => {
  if (auth) {
    return <AuthRoute redirectTo="/" {...props} />;
  } else if (noauth) {
    return <UnauthRoute redirectTo="/kde/2023" {...props} />;
  } else {
    return <Route {...props} />;
  }
};

const mapStateToProps = (state) => ({
  authenticated: selectAuthenticated(state),
});

export default connect(mapStateToProps)(ConnectedRoute);
