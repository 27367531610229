import { EventStats } from "features/events/reducer";
import { join, zip } from "helpers/utils";
import React from "react";

import css from "./event.module.css";

const labels = [
  "Vegan",
  "Vegetarian",
  "Pescetarian",
  "Laktosintolerant",
  "Gluten",
];

const drinkLabels = { alcoholic: "Alkohol", nonAlcoholic: "Alkoholfri" };
const ticketLabels = { student: "Student", alumni: "Alumni" };

type Preference = [boolean, boolean, boolean, boolean, boolean, string];

// TODO: Refactor this shit code
const label = (preference: Preference) =>
  zip(labels, preference)
    .filter(([_, v]) => !!v)
    .map(([k, _]) => k)
    .join(" + ");

const text = (preference: Preference) => preference[5];

const joinWithText = (preference: Preference) => {
  const ary = [label(preference), text(preference)];
  return join(ary, " + ");
};

const foodLabel = ([k, v]: [string, number]) => {
  return {
    key: joinWithText(JSON.parse(k)),
    value: v,
  };
};

type EventSummaryProps = {
  stats: EventStats;
};

const EventSummary = ({ stats }: EventSummaryProps) => {
  const food = Object.entries(stats.foodPreferences).map(foodLabel);

  const drink = [
    { key: drinkLabels.alcoholic, value: stats.drinkPreferences.alcoholic },
    {
      key: drinkLabels.nonAlcoholic,
      value: stats.drinkPreferences.nonAlcoholic,
    },
  ];

  const tickets = [
    { key: ticketLabels.student, value: stats.ticketTypes.student },
    { key: ticketLabels.alumni, value: stats.ticketTypes.alumni },
  ];

  return (
    <div className={css.summary}>
      <Count title="Matpreferenser" items={food} />
      <Count title="Dryckespreferenser" items={drink} />
      <Count title="Biljetter" items={tickets} />
    </div>
  );
};

type CounterProps = {
  title: string;
  items: Array<{ key: string; value: number }>;
};

const Count = ({ title, items }: CounterProps) => {
  if (items.length === 0) return null;
  if (title === "") return null;
  return (
    <div className={css.counts}>
      <h3>{title}</h3>
      {items
        .filter(({ key }) => !!key)
        .map(({ key, value }) => (
          <div key={key} className={css.item}>
            <h4 className={css.count}>{value || 0}</h4> &nbsp;
            <h4 className={css.title}>{key}</h4>
          </div>
        ))}
    </div>
  );
};

export default EventSummary;
