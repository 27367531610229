import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(RouterLink)`
  border-bottom: 2px solid transparent !important;

  &:hover {
    border-bottom: 2px solid !important;
  }
`;

export const ButtonLink = styled(RouterLink)`
  background: #f9f9fa;
  color: #ee2a7b !important;
  border: none;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-family: inherit;

  &:hover {
    cursor: pointer;
  }
`;
