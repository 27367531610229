import { Box } from "components/common";
import { Person } from "features/people/types";
import React, { ReactNode } from "react";

type PersonProps = Pick<Person, "slug" | "name" | "schoolYearName">;

type Props = {
  person: PersonProps;
  withYear?: boolean;
  children?: ReactNode;
};

export default function PersonBox({
  person,
  withYear = false,
  children,
}: Props) {
  if (!person) {
    return null;
  }

  const { name, schoolYearName, slug } = person;
  const url = `/drifvare/${slug}`;

  if (withYear) {
    return (
      <Box url={url} title={name} subtitle={schoolYearName} primary>
        {children}
      </Box>
    );
  }

  return (
    <Box url={url} title={name} primary>
      {children}
    </Box>
  );
}
