import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { get } from "api";
import { Tenure } from "features/tenures";
import { Slug } from "helpers/types";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export type Role = {
  id: number;
  slug: string;
  name: string;
  order: number;
  tenures: Array<Tenure>;
};

export const roleAdapter = createEntityAdapter<Role>({
  selectId: (role) => role.slug,
  sortComparer: (a, b) => a.order - b.order,
});

export const getRole = createAsyncThunk<Role, string>(
  "roles/getRole",
  async (slug: string) => {
    return await get<Role>(`/roles/${slug}`);
  }
);
const roleSlice = createSlice({
  name: "roles",
  initialState: roleAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRole.fulfilled, roleAdapter.setOne);
  },
});

export default roleSlice.reducer;

export const { selectAll: selectAllRoles, selectById: selectRole } =
  roleAdapter.getSelectors((state: RootState) => state.roles);

export function useRole(slug: Slug<Role>) {
  return useAppSelector((state) => selectRole(state, slug));
}
