import keyBy from "lodash/keyBy";

export const arrange = <T>(
  array: Array<T>,
  key: string = "id"
): {
  [key: string]: T;
} => {
  return keyBy(array, key);
};

export const range = (from: number, to: number): Array<number> => {
  const size = to - from + 1;
  return Array.from(Array(size).keys()).map((i) => i + from);
};

export function zip<T>(a: Array<T>, b: Array<T>): Array<Array<T>> {
  return a.map((value, index) => [value, b[index]]);
}

export const join = (ary: Array<unknown>, delimiter = ", "): string => {
  return ary.filter((v) => !!v).join(delimiter);
};
