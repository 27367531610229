import { Box, ButtonLink } from "components/common";
import { useIsAdmin } from "features/people/reducer";
import { getTeams } from "features/teams/actions";
import { useTeams } from "features/teams/reducer";
import React, { useEffect } from "react";
import { useAppDispatch } from "store/hooks";

const Teams = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  const teams = useTeams();

  return (
    <div>
      <h1>Drifverier</h1>
      <Controls />
      {teams.map((team) => (
        <Team key={team.year} {...team} />
      ))}
    </div>
  );
};

const Team = ({ name, year, members }) => {
  const url = `/drifveri/${year}`;
  const names = members.map((m) => m.firstName).join(", ");
  return (
    <Box url={url} title={name} primary>
      {names}
    </Box>
  );
};

function Controls() {
  const isAdmin = useIsAdmin();
  if (!isAdmin) return null;
  return (
    <div>
      <ButtonLink to="/drifveri/new">Nytt drifveri</ButtonLink>
    </div>
  );
}

export default Teams;
