import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    lightBackgroundColor: string;
    darkBackgroundColor: string;
    primaryBackgroundColor: string;

    primaryTextColor: string;
    accentTextColor: string;
    darkAccentTextcolor: string;
  }
}

export const modernTheme: DefaultTheme = {
  lightBackgroundColor: "#ec5f99",
  darkBackgroundColor: "#ee2a7b",
  primaryBackgroundColor: "#e83d84",

  primaryTextColor: "#1e1d21",
  accentTextColor: "#f9f9fa",
  darkAccentTextcolor: "#ee2a7b",
};
