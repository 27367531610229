import "./App.css";

import Authentication from "components/Authentication";
import Route from "components/common/Route";
import Event from "components/events/Event";
import EventAdmin from "components/events/EventAdmin";
import { CreateEventPage, EditEventPage } from "components/events/EventForm";
import Home from "components/Home";
import Layout from "components/Layout";
import Login from "components/Login";
import PasswordReset from "components/login/PasswordReset";
import People from "components/people/People";
import PersonForm from "components/people/PersonForm";
import Profile from "components/people/Profile";
import ProfileForm from "components/people/ProfileForm";
import Role from "components/roles/Role";
import Team from "components/teams/Team";
import TeamForm from "components/teams/TeamForm";
import Teams from "components/teams/Teams";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { store } from "store/store";

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </Provider>
);

const Main = () => (
  <Authentication>
    <Layout>
      <Routes />
    </Layout>
  </Authentication>
);

const Routes = () => (
  <Switch>
    <Route noauth exact path="/login" component={Login} />
    <Route noauth exact path="/password-reset" component={PasswordReset} />

    <Route exact path="/" component={Home} />
    <Route auth exact path="/drifvare" component={People} />
    <Route auth path="/drifvare/new" component={PersonForm} />
    <Route auth path="/drifvare/titel/:slug" component={Role} />
    <Route auth path="/drifvare/:slug" component={Profile} />
    <Route auth exact path="/profile/edit" component={ProfileForm} />
    <Route auth exact path="/kde" component={CreateEventPage} />
    <Route auth exact path="/kde/:year" component={Event} />
    <Route auth exact path="/kde/:year/edit" component={EditEventPage} />
    <Route auth exact path="/kde/:year/admin" component={EventAdmin} />
    <Route auth exact path="/drifverier/" component={Teams} />
    <Route auth path="/drifveri/new" component={TeamForm} />
    <Route auth exact path="/drifveri/:year" component={Team} />
  </Switch>
);

export default App;
