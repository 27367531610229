import { Button } from "components/common";
import Select from "components/common/Select";
import { Email, Password } from "features/authentication/types";
import { updatePerson } from "features/people/actions";
import { useCurrentPerson } from "features/people/reducer";
import {
  Address,
  Person,
  UpdatePersonParams,
  User,
} from "features/people/types";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { Id, Slug } from "helpers/types";
import { range } from "helpers/utils";
import React from "react";
import { useAppDispatch } from "store/hooks";
import css from "styles/forms/form.module.css";

const today = new Date().getFullYear();

const years: Array<YearOption> = range(today - 3, today + 3)
  .sort()
  .reverse()
  .map((y) => ({ value: y, label: y }));

type YearOption = {
  value: number;
  label: number;
};

type ProfileFormValues = PersonFormValues & AddressFormValues;

type PersonFormValues = {
  slug: Slug<Person>;
  addressId: Id<Address>;
  userId: Id<User>;
  email: Email;
  password: Password;
  firstName: string;
  lastName: string;
  schoolYear: YearOption;
};

type AddressFormValues = {
  street: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
};

function mapParams(values: ProfileFormValues): UpdatePersonParams {
  return {
    ...values,
    schoolYear: values.schoolYear.value,
  };
}

function mapInitialValues(person: Person): ProfileFormValues {
  const {
    address,
    slug,
    firstName,
    lastName,
    schoolYear,
    user: { id: userId, email },
  } = person;
  const { id: addressId, street, zipCode, city, state, country } = address;
  return {
    slug,
    userId,
    password: "",
    firstName,
    lastName,
    schoolYear: { value: schoolYear, label: schoolYear } || years[0],
    email,
    addressId,
    street,
    zipCode,
    city,
    state,
    country,
  };
}

export function ProfileFormPage() {
  const dispatch = useAppDispatch();
  const person = useCurrentPerson();

  if (!person) {
    return null;
  }

  const handleSubmit = (
    values: ProfileFormValues,
    actions: FormikHelpers<any>
  ) => {
    const params = mapParams(values);

    dispatch(updatePerson(params)).then(() => {
      actions.setSubmitting(false);
    });
  };

  const initialValues = mapInitialValues(person);

  return (
    <div className={css.form}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        component={ProfileForm}
      />
    </div>
  );
}

function ProfileForm({
  values,
  isSubmitting,
  setFieldValue,
  handleBlur,
  handleSubmit,
}: FormikProps<ProfileFormValues>) {
  return (
    <Form>
      <Field hidden name="slug" />
      <Field hidden name="addressId" />
      <Field hidden name="userId" />

      <h4 className={css.header}>Användare</h4>
      <div className={css.wrapper}>
        <label>E-mail</label>
        <Field name="email" type="email" />
      </div>

      <div className={css.wrapper}>
        <label>Lösenord</label>
        <Field name="password" type="password" />
      </div>

      <h4 className={css.header}>Profil</h4>
      <div className={css.wrapper}>
        <label>Förnamn</label>
        <Field name="firstName" />
      </div>

      <div className={css.wrapper}>
        <label>Efternamn</label>
        <Field name="lastName" />
      </div>

      <div className={css.wrapper}>
        <label>Årskurs</label>
        <Field
          name="schoolYear"
          component={Select}
          options={years}
          value={values.schoolYear}
          onBlur={handleBlur}
          onChange={setFieldValue}
        />
      </div>

      <h4 className={css.header}>Adress</h4>
      <div className={css.wrapper}>
        <label>Gatuadress</label>
        <Field name="street" />
      </div>
      <div className={css.wrapper}>
        <label>Postkod</label>
        <Field name="zipCode" />
      </div>
      <div className={css.wrapper}>
        <label>Stad</label>
        <Field name="city" />
      </div>
      <div className={css.wrapper}>
        <label>Delstat/region</label>
        <Field name="state" />
      </div>
      <div className={css.wrapper}>
        <label>Land</label>
        <Field name="country" />
      </div>

      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        Spara
      </Button>
    </Form>
  );
}

export default ProfileFormPage;
