import { Button, ButtonLink, Link } from "components/common";
import EventResponseForm from "components/events/EventResponseForm";
import Person from "components/people/Person";
import { getEvent } from "features/events/reducer";
import { Event, selectEventById } from "features/events/reducer";
import { getPeopleList } from "features/people/actions";
import { useIsAdmin, usePerson } from "features/people/reducer";
import { date } from "helpers/date";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Remark } from "react-remark";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

import css from "./event.module.css";

type Props = {
  year: string;
};

function ErrorMessage({ year }: Props) {
  return (
    <div className={css.event}>
      <EventNavigation year={parseInt(year)} />
      <h1 className={css.title}>404: KDE not found</h1>
      <p>
        Det här eventet finns inte. Antingen är det inte skapat än, eller så är
        nånting trasigt. Försök att ladda om sidan, eller hör av dig till någon
        i Titeldrifveriet.
      </p>
    </div>
  );
}

function EventContainer({ match }: RouteComponentProps<Props>) {
  const [showForm, setShowForm] = useState(false);

  const dispatch = useAppDispatch();

  const { year } = match.params;

  useEffect(() => {
    dispatch(getEvent(parseInt(year)));
    dispatch(getPeopleList());
  }, [dispatch, year]);

  const event = useAppSelector((state) => selectEventById(state, year));

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  if (!event) {
    return <ErrorMessage year={year} />;
  }

  return (
    <EventPage
      event={event}
      slug={year}
      showForm={showForm}
      toggleForm={toggleForm}
    />
  );
}

const EventNavigation = ({ year }: { year: number }) => {
  const prev = year - 1;
  const next = year + 1;
  return (
    <div className={css.controls}>
      <Link to={`/kde/${prev}`}>KDE {prev}</Link>
      <Link to={`/kde/${next}`}>KDE {next}</Link>
    </div>
  );
};

const Admin = ({ year }: { year: number }) => {
  const isAdmin = useIsAdmin();
  if (!isAdmin) return null;
  return <ButtonLink to={`/kde/${year}/admin`}>Admin</ButtonLink>;
};

type EventProps = {
  slug: string;
  event: Event;
  showForm: boolean;
  toggleForm: () => void;
};

const EventPage = ({
  event: { name, year, when, description, attending, signupDeadline },
  slug,
  showForm,
  toggleForm,
}: EventProps) => {
  const day = date(when, "dddd, Do MMMM");
  const deadline = date(signupDeadline, "dddd, Do MMMM");
  const signupClosed = moment().isAfter(moment(signupDeadline));
  const time = date(when, "LT");

  return (
    <div className={css.event}>
      <EventNavigation year={year} />
      <Admin year={year} />
      <h1 className={css.title}>{name}</h1>
      <h2 className={css.when}>
        {day} {time}
      </h2>

      <Remark>{description}</Remark>

      {signupClosed && <p>Anmälan har tyvärr stängt!</p>}
      {!signupClosed && !!deadline && (
        <>
          <p>
            <b>Anmälan stänger</b> {deadline}
          </p>
          <Button onClick={toggleForm}>Anmäl dig för bövelen!</Button>
        </>
      )}

      {showForm && !signupClosed && <EventResponseForm event={slug} />}

      <h3>Anmälda ({attending.length} st)</h3>
      <div className={css.responses}>
        {attending.length > 0 &&
          attending.map((response) => (
            <Response key={response.person.slug} {...response} />
          ))}
      </div>
      {attending.length === 0 && <p>Ingen har anmält sig än...</p>}
    </div>
  );
};

const Response = ({ ...props }) => {
  const person = usePerson(props.person.slug);
  if (!person) return null;
  return (
    <Person person={person} withYear>
      {props.comment}
    </Person>
  );
};

export default EventContainer;
