import { Button } from "components/common";
import Select from "components/common/Select";
import { createPerson } from "features/people/actions";
import { Field, Form, Formik } from "formik";
import { range } from "helpers/utils";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import css from "styles/forms/form.module.css";

const today = new Date().getFullYear() - 1;
const years = range(1983, today)
  .sort()
  .reverse()
  .map((y) => ({ value: y, label: y }));

class PersonFormContainer extends Component {
  handleSubmit = (values, actions) => {
    const params = {
      ...values,
      schoolYear: values.schoolYear.value,
    };
    this.props.createPerson(params).then(() => {
      actions.setSubmitting(false);
    });
  };

  prefilled = () => {
    return {
      firstName: "",
      lastName: "",
      personalNumber: "",
      kthId: "",
      email: "",
      schoolYear: { value: today, label: today },
    };
  };

  render = () => {
    const values = this.prefilled();

    return (
      <div className={css.form}>
        <Formik
          initialValues={values}
          onSubmit={this.handleSubmit}
          component={PersonForm}
        />
      </div>
    );
  };
}

const mapState = (state, props) => {
  return {};
};

const mapDispatch = (dispatch) =>
  bindActionCreators({ createPerson }, dispatch);

const PersonForm = (props) => {
  const { values, isSubmitting, setFieldValue, handleBlur } = props;
  return (
    <Form>
      <Field hidden name="id" />

      <div className={css.wrapper}>
        <label>Förnamn</label>
        <Field name="firstName" />
      </div>

      <div className={css.wrapper}>
        <label>Efternamn</label>
        <Field name="lastName" />
      </div>

      <div className={css.wrapper}>
        <label>Personnummer</label>
        <Field name="personalNumber" />
      </div>

      <div className={css.wrapper}>
        <label>KTH-id</label>
        <Field name="kthId" />
      </div>

      <div className={css.wrapper}>
        <label>Email</label>
        <Field name="email" type="email" />
      </div>

      <label>Årskurs</label>
      <Field
        name="schoolYear"
        component={Select}
        options={years}
        value={values.schoolYear}
        onBlur={handleBlur}
        onChange={setFieldValue}
      />

      <Button type="submit" disabled={isSubmitting}>
        Skapa person
      </Button>
    </Form>
  );
};

export default connect(mapState, mapDispatch)(PersonFormContainer);
