import { Logo } from "components/header/Topbar";
import LoginFormContainer from "components/login/LoginFormContainer";
import React from "react";

const LoginPage = () => {
  return (
    <div>
      <Logo />
      <LoginFormContainer />
    </div>
  );
};

export default LoginPage;
