import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { get } from "api";
import { Person } from "features/people/types";
import { Role } from "features/roles";
import { Reference, Slug } from "helpers/types";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export type Tenure = {
  id: number;
  year: number;
  person: Reference<Person>;
  role: Reference<Role>;
};

const tenureAdapter = createEntityAdapter<Tenure>({
  selectId: (tenure) => tenure.id,
  sortComparer: (a, b) => b.year - a.year,
});

export const getTenures = createAsyncThunk<Array<Tenure>, string>(
  "tenures/getTenures",
  async (slug: string) => {
    return await get<Array<Tenure>>(`roles/${slug}/tenures`);
  }
);

const tenureSlice = createSlice({
  name: "tenures",
  initialState: tenureAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTenures.fulfilled, tenureAdapter.addMany);
  },
});

export default tenureSlice.reducer;

export const { selectAll: selectAllTenures } = tenureAdapter.getSelectors(
  (state: RootState) => state.tenures
);

export const { selectAll } = tenureAdapter.getSelectors();

export const selectByRole = createSelector(
  selectAllTenures,
  (state: RootState, slug: Slug<Role>) => slug,
  (tenures, slug) => tenures.filter((tenure) => tenure.role.slug === slug)
);

export function useTenures(slug: Slug<Role>) {
  return useAppSelector((state) => selectByRole(state, slug));
}
