import { configureStore } from "@reduxjs/toolkit";
import authentication from "features/authentication/reducer";
import events from "features/events/reducer";
import memberships from "features/memberships";
import people from "features/people/reducer";
import roles from "features/roles";
import teams from "features/teams/reducer";
import tenures from "features/tenures";

export const store = configureStore({
  reducer: {
    authentication,
    people,
    events,
    memberships,
    roles,
    teams,
    tenures,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
