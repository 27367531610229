import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import decode from "jwt-decode";

import { login } from "./api";
import { Credentials, JwtTokenData, Token } from "./types";

const localStorageKeys = {
  id: "dkd-auth-id",
  token: "dkd-auth-token",
  expiry: "dkd-auth-token-expiration",
  slug: "dkd-auth-slug",
};

type AuthenticationState = (AuthenticatedState | UnauthenticatedState) &
  AuthenticationRequestState;

type AuthenticationRequestState = {
  loading: boolean;
};

interface AuthenticatedState {
  authenticated: true;
  id: string;
  token: Token;
  slug: string;
}

interface UnauthenticatedState {
  authenticated: false;
}

export const authenticate = createAsyncThunk<
  Token,
  Credentials,
  { state: { authentication: AuthenticationState } }
>("authentication/authenticate", async (credentials: Credentials) => {
  const { token } = await login(credentials);
  return token;
});

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: getLocalAuthenticationState(),
  reducers: {
    logout: (state) => {
      clearLocalAuthenticationState();

      state.authenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      authenticate.fulfilled,
      (state, action: PayloadAction<Token>) => {
        const token: Token = action.payload;
        setLocalAuthenticationState(token);

        const { user_id: id, slug } = decode<JwtTokenData>(token);

        return {
          authenticated: true,
          loading: false,
          id,
          token,
          slug,
        };
      }
    );
    builder.addCase(authenticate.rejected, (state) => {
      state.loading = false;
      state.authenticated = false;
    });
  },
});

export const { logout } = authenticationSlice.actions;
export default authenticationSlice.reducer;

function setLocalAuthenticationState(token: Token) {
  const { user_id: id, slug, exp } = decode<JwtTokenData>(token);

  if (!exp) {
    throw new Error("Token has no expiry");
  }

  localStorage.setItem(localStorageKeys.id, id);
  localStorage.setItem(localStorageKeys.token, token);
  localStorage.setItem(localStorageKeys.expiry, exp.toString());
  localStorage.setItem(localStorageKeys.slug, slug);
}

export function getLocalAuthenticationState(): AuthenticationState {
  const id = localStorage.getItem(localStorageKeys.id);

  if (!id) {
    return {
      authenticated: false,
      loading: false,
    };
  }

  const token = localStorage.getItem(localStorageKeys.token);

  if (!token) {
    return {
      authenticated: false,
      loading: false,
    };
  }

  const exp = parseInt(localStorage.getItem(localStorageKeys.expiry) ?? "0");
  const expired = exp * 1000 < new Date().getTime();
  if (expired) {
    return {
      authenticated: false,
      loading: false,
    };
  }

  const slug = localStorage.getItem(localStorageKeys.slug);

  if (!slug) {
    return {
      authenticated: false,
      loading: false,
    };
  }

  return {
    authenticated: true,
    loading: false,
    id,
    token,
    slug,
  };
}

function clearLocalAuthenticationState() {
  localStorage.removeItem(localStorageKeys.id);
  localStorage.removeItem(localStorageKeys.token);
  localStorage.removeItem(localStorageKeys.expiry);
  localStorage.removeItem(localStorageKeys.slug);
}
