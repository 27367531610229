import { ButtonLink } from "components/common";
import PeopleList from "components/people/PeopleList";
import { getPeople } from "features/people/actions";
import { useIsAdmin, usePeople } from "features/people/reducer";
import React, { useEffect } from "react";
import { useAppDispatch } from "store/hooks";

import css from "./people.module.css";

export default function PeopleContainer() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPeople());
  }, [dispatch]);

  const people = usePeople();

  return (
    <div className={css.wrapper}>
      <h1>Drifvare</h1>
      <Controls />
      <PeopleList people={people} />
    </div>
  );
}

const Controls = () => {
  const isAdmin = useIsAdmin();
  if (!isAdmin) return null;
  return (
    <div>
      <ButtonLink to="/drifvare/new">Ny drifvare</ButtonLink>
      {/*<ExportAddressesButton />*/}
    </div>
  );
};
