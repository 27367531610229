import { ButtonLink } from "components/common";
import React from "react";
import styled from "styled-components";

const NavigationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`;

const NavigationLeft = styled(ButtonLink)`
  justify-self: start;
`;

const NavigationRight = styled(ButtonLink)`
  justify-self: end;
`;

export type NavigationPropsItem = {
  url: string;
  label: string;
};

export type NavigationProps = {
  prev: NavigationPropsItem;
  next: NavigationPropsItem;
};

const Navigation = ({ prev, next }: NavigationProps) => {
  return (
    <NavigationWrapper>
      {prev.url && (
        <NavigationLeft to={prev.url}>&larr; {prev.label}</NavigationLeft>
      )}
      {next.url && (
        <NavigationRight to={next.url}>{next.label} &rarr;</NavigationRight>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
