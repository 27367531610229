import { Link } from "components/common";
import ImageUpload from "components/common/ImageUpload";
import Portrait from "components/people/Portrait";
import { updatePortrait } from "features/memberships";
import { useIsAdmin, useIsSelf } from "features/people/reducer";
import { Person } from "features/people/types";
import { Reference, UploadImageParams } from "helpers/types";
import React from "react";
import { useAppDispatch } from "store/hooks";
import styled from "styled-components";

const PersonDisplayListWrapper = styled.div`
  flex: 1;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
`;

const PersonDisplayListSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const PersonDisplayListSectionTitle = styled.h3`
  margin: 0 auto;
`;

type PersonDisplayListProps = {
  title: string;
  people: Array<PersonDisplayProps>;
};

const PersonDisplayList = ({ title, people }: PersonDisplayListProps) => (
  <PersonDisplayListSection>
    <PersonDisplayListSectionTitle>{title}</PersonDisplayListSectionTitle>

    <PersonDisplayListWrapper>
      {people.map((person) => (
        <PersonDisplay key={person.id} {...person} />
      ))}
    </PersonDisplayListWrapper>
  </PersonDisplayListSection>
);

const PersonDisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #000;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
`;

const Title = styled.h4`
  margin: 0;
`;

const Year = styled.h4`
  margin: 0;
  color: #fff;
`;

export type PersonDisplayProps = {
  id: number;
  title: string;
  subtitle?: string;
  url: string;
  image?: string; // TODO change to image type
  person: Reference<Person>;
};

function PersonDisplay({
  id,
  title,
  subtitle,
  image,
  url,
  person: { slug },
}: PersonDisplayProps) {
  const isSelf = useIsSelf(slug);
  const isAdmin = useIsAdmin();

  return (
    <PersonDisplayWrapper>
      {image && <Portrait image={image} />}
      <Link to={url}>
        <Title>{title}</Title>
      </Link>
      {subtitle && <Year>{subtitle}</Year>}

      {(isSelf || isAdmin) && <PortraitUpload entityId={id} />}
    </PersonDisplayWrapper>
  );
}

function PortraitUpload({ entityId }: { entityId: number | string }) {
  const dispatch = useAppDispatch();

  const uploadImage = (params: UploadImageParams) => {
    return dispatch(updatePortrait(params));
  };

  return <ImageUpload entityId={entityId} uploadImage={uploadImage} />;
}

export default PersonDisplayList;
