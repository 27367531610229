import Whip from "images/whip.svg";
import React from "react";
import css from "styles/layout.module.css";

const Home = () => {
  return (
    <div className={css.home}>
      <img className={css.whip} src={Whip} alt="" />
    </div>
  );
};

export default Home;
