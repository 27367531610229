import ImageUpload from "components/common/ImageUpload";
import PersonBox from "components/people/Person";
import AddPersonForm from "components/teams/AddPersonForm";
import Navigation from "components/teams/Navigation";
import {
  getMembershipsByYear,
  Membership,
  useMembershipsByYear,
} from "features/memberships";
import { getPeople } from "features/people/actions";
import { useIsAdmin, usePerson } from "features/people/reducer";
import { getTeam, updateCover } from "features/teams/actions";
import { useTeam } from "features/teams/reducer";
import { Team } from "features/teams/types";
import { UploadImageParams } from "helpers/types";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import styled from "styled-components";

import css from "./team.module.css";

type Props = {
  year: string;
};

function TeamContainer({ match }: RouteComponentProps<Props>) {
  const { year } = match.params;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTeam(parseInt(year)));
    dispatch(getMembershipsByYear(parseInt(year)));
    dispatch(getPeople());
  }, [dispatch, year]);

  const team = useTeam(parseInt(year));

  if (!team) {
    return <div>Loading...</div>;
  }

  return <TeamPage {...team} year={parseInt(year)} />;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeamPage = ({ name, year, cover }: Team) => {
  const members = useMembershipsByYear(year);

  console.log(members);

  return (
    <Wrapper>
      <h1 className={css.heading}>{name}</h1>
      <Navigation year={year} />
      <div className={css.cover}>
        <img alt={`Omslagsbild för Drifveriet ${year}`} src={cover} />
      </div>
      <h4 className={css.heading}>Drifvare</h4>
      {members.map((member) => {
        return <Member key={member.person.slug} membership={member} />;
      })}
      <Controls year={year} />
    </Wrapper>
  );
};

type MemberProps = {
  membership: Membership;
};

const Member = ({ membership }: MemberProps) => {
  const person = usePerson(membership.person.slug);
  if (!person) {
    return null;
  }
  return (
    <PersonBox person={person} withYear>
      {membership.title}
    </PersonBox>
  );
};

const Controls = ({ year }: { year: number }) => {
  const isAdmin = useIsAdmin();
  if (!isAdmin) return null;
  return (
    <div>
      <AddPersonForm team={year} />
      <label>Ladda upp omslagsbild</label>
      <CoverUpload entityId={year} />
    </div>
  );
};

function CoverUpload({ entityId }: { entityId: number | string }) {
  const dispatch = useAppDispatch();

  const uploadImage = (params: UploadImageParams) => {
    return dispatch(updateCover(params));
  };

  return <ImageUpload entityId={entityId} uploadImage={uploadImage} />;
}

export default TeamContainer;
