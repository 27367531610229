import React from "react";
import Select from "react-select";

const styles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
    };
  },
};

class DKDSelect extends React.Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.field.name, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.field.name, true);
  };

  render() {
    const { id, options, multi, label } = this.props;
    return (
      <div>
        <label htmlFor="color">{label}</label>
        <Select
          id={id}
          options={options}
          multi={multi}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
          styles={styles}
        />
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}

DKDSelect.defaultProps = {
  multi: false,
};

export default DKDSelect;
