import React from "react";

export function Table({ rows }: { rows: Array<TableRowData> }) {
  return (
    <table>
      {rows.map(row => <TableRow row={row} />)}
    </table>
  );
}

export type TableRowData = {
  title: string;
  data: Array<string>;
}

function TableRow({ row }: { row: TableRowData }) {
  const { title, data } = row
  return (
    <tr>
      <th scope="row">
        {title}
      </th>
      {data.map(datum => <TableDataItem text={datum} />)}
    </tr>
  )
}

function TableDataItem({ text }: { text: string }) {
  return <td>{text}</td>
}