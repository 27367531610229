import Person from "components/people/Person";
import React, { Component } from "react";

class PeopleList extends Component {
  render() {
    return this.props.people.map((person) => (
      <Person key={person.slug} person={person} withYear />
    ));
  }
}

PeopleList.defaultProps = {
  people: [],
};

export default PeopleList;
