import {
  selectAuthenticated,
  selectSlug,
} from "features/authentication/actions";
import { getPerson } from "features/people/actions";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Authentication extends Component {
  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    if (this.props.authenticated) {
      this.props.getPerson(this.props.slug);
    }
  };

  render() {
    return this.props.children;
  }
}

const mapState = (state) => ({
  authenticated: selectAuthenticated(state),
  slug: selectSlug(state),
});

const mapDispatch = (dispatch) => bindActionCreators({ getPerson }, dispatch);

export default connect(mapState, mapDispatch)(Authentication);
