import ActiveStorageUpload from "components/ActiveStorageUpload";
import React from "react";

const ImageUpload = ({ entityId, uploadImage }) => {
  return (
    <ActiveStorageUpload
      url={process.env.REACT_APP_DKD_API_URL}
      upload={(ids) => uploadImage({ entityId, image: ids[0] })}
      render={({ handleUpload, uploads, ready }) => (
        <div>
          <input
            type="file"
            disabled={!ready}
            onChange={(e) => handleUpload(e.currentTarget.files)}
          />

          {uploads.map((upload) =>
            upload.state === "waiting" ? (
              <p key={upload.id}>Waiting to upload {upload.file.name}</p>
            ) : upload.state === "uploading" ? (
              <p key={upload.id}>
                Uploading {upload.file.name}: {upload.progress}%
              </p>
            ) : upload.state === "error" ? (
              <p key={upload.id}>
                Error uploading {upload.file.name}: {upload.error}
              </p>
            ) : (
              <p key={upload.id}>Finished uploading {upload.file.name}</p>
            )
          )}
        </div>
      )}
    />
  );
};

export default ImageUpload;
