import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { get, put } from "api";
import { Person } from "features/people/types";
import { Team } from "features/teams/types";
import { Reference, Slug, UploadImageParams } from "helpers/types";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

export type Membership = {
  id: number;
  title: string;
  year: number;
  portrait?: string;
  order: number;
  person: Reference<Person>;
  team: Reference<Team>;
};

export const membershipAdapter = createEntityAdapter<Membership>({
  selectId: (membership) => membership.id,
  sortComparer: (a, b) =>
    a.order - b.order || b.year - a.year || a.title.localeCompare(b.title),
});

export const getMemberships = createAsyncThunk<Array<Membership>, Slug<Person>>(
  "memberships/getMemberships",
  async (slug: Slug<Person>) => {
    return await get<Array<Membership>>(`/people/${slug}/team_memberships`);
  }
);

export const getMembershipsByYear = createAsyncThunk<Array<Membership>, number>(
  "memberships/getMembershipByYear",
  async (year: number) => {
    return await get<Array<Membership>>(`/teams/${year}/members`);
  }
);

export const updatePortrait = createAsyncThunk<Membership, UploadImageParams>(
  "memberships/updatePortrait",
  async ({ entityId, image }: UploadImageParams) => {
    const body = {
      teamMembership: {
        portrait: image,
      },
    };
    return await put<Membership>(`/team_memberships/${entityId}`, body);
  }
);

const membershipSlice = createSlice({
  name: "memberships",
  initialState: membershipAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMemberships.fulfilled, membershipAdapter.addMany);
    builder.addCase(
      getMembershipsByYear.fulfilled,
      membershipAdapter.upsertMany
    );
    builder.addCase(updatePortrait.fulfilled, membershipAdapter.upsertOne);
  },
});

export default membershipSlice.reducer;

export const { selectAll: selectAllMemberships, selectById: selectMembership } =
  membershipAdapter.getSelectors((state: RootState) => state.memberships);

export const selectByPerson = createSelector(
  selectAllMemberships,
  (state: RootState, slug: Slug<Person>) => slug,
  (memberships, slug) =>
    memberships.filter((membership) => membership.person.slug === slug)
);

export function useMemberships(slug: Slug<Person>) {
  return useAppSelector((state) => selectByPerson(state, slug));
}

export const selectByYear = createSelector(
  selectAllMemberships,
  (state: RootState, year: number) => year,
  (memberships, year) => {
    return memberships.filter(
      (membership) => String(membership.year) === String(year)
    );
  }
);

export function useMembershipsByYear(year: number) {
  return useAppSelector((state) => selectByYear(state, year));
}
