import * as ActiveStorage from "activestorage";
import { getLocalAuthenticationState } from "features/authentication/reducer";

const DIRECT_UPLOADS_PATH = "/rails/active_storage/direct_uploads";

class Upload {
  constructor(file, options) {
    this.options = options;

    if (options.url) {
      this.uploadUrl = `${options.url}${DIRECT_UPLOADS_PATH}`;
    } else {
      this.uploadUrl = `${DIRECT_UPLOADS_PATH}`;
    }

    this.directUpload = new ActiveStorage.DirectUpload(
      file,
      this.uploadUrl,
      this
    );
    this.id = `${this.directUpload.id}`;

    this.handleChangeFile({ state: "waiting", id: this.id, file });
  }

  start = () => {
    const promise = new Promise((resolve, reject) => {
      this.directUpload.create((error, attributes) => {
        if (error) {
          reject(error);
        }
        resolve(attributes.signed_id);
      });
    });

    return promise.then(this.handleSuccess).catch(this.handleError);
  };

  handleChangeFile = (upload) => {
    this.options.onChangeFile({ [this.id]: upload });
  };

  handleProgress = ({ loaded, total }) => {
    const progress = (loaded / total) * 100;

    this.handleChangeFile({
      state: "uploading",
      file: this.directUpload.file,
      id: this.id,
      progress,
    });
  };

  handleSuccess = (id) => {
    this.handleChangeFile({
      state: "finished",
      id: this.id,
      file: this.directUpload.file,
    });
    return id;
  };

  handleError = (error) => {
    this.handleChangeFile({
      state: "error",
      id: this.id,
      file: this.directUpload.file,
      error: error.message,
    });
    throw error;
  };

  directUploadWillCreateBlobWithXHR(xhr) {
    const accessToken = getLocalAuthenticationState().token;
    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    this.options.onBeforeBlobRequest &&
      this.options.onBeforeBlobRequest({
        id: this.id,
        file: this.directUpload.file,
        xhr,
      });
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.options.onBeforeStorageRequest &&
      this.options.onBeforeStorageRequest({
        id: this.id,
        file: this.directUpload.file,
        xhr,
      });

    xhr.upload.addEventListener("progress", this.handleProgress);
  }
}

export default Upload;
