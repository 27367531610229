import { Button } from "components/common";
import Select from "components/common/Select";
import { getPeopleList } from "features/people/actions";
import { usePeople } from "features/people/reducer";
import { addMember } from "features/teams/actions";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import css from "styles/forms/form.module.css";

type Props = {
  team: number;
};

function AddMemberFormContainer({ team }: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPeopleList());
  }, [dispatch]);

  const people = usePeople();

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const params = {
      teamId: values.team,
      person: values.person.value,
    };

    dispatch(addMember(params)).then(() => {
      actions.setSubmitting(false);
      actions.resetForm();
    });
  };

  const initialValues: FormValues = {
    person: {
      value: "",
      label: "",
    },
    team: team,
  };

  const options = people.map((person) => ({
    value: person.slug,
    label: person.name,
  }));

  return (
    <div className={css.form}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => <AddMemberForm {...props} options={options} />}
      </Formik>
    </div>
  );
}

type FormValues = {
  person: FormOption;
  team: number;
};

type FormOption = {
  label: string;
  value: string;
};

type FormProps = {
  options: Array<FormOption>;
};

function AddMemberForm({
  options,
  values,
  isSubmitting,
  setFieldValue,
  handleBlur,
  handleSubmit,
}: FormProps & FormikProps<FormValues>) {
  return (
    <Form>
      <Field hidden name="team" value={values.team} />

      <label>Person</label>
      <Field
        name="person"
        component={Select}
        options={options}
        value={values.person}
        onBlur={handleBlur}
        onChange={setFieldValue}
      />

      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
        Lägg till drifvare
      </Button>
    </Form>
  );
}

export default AddMemberFormContainer;
