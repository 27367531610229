import api from "api";

import { Email, Password, Token } from "./types";

export function login({
  email,
  password,
}: {
  email: Email;
  password: Password;
}): Promise<{ token: Token }> {
  return api(
    "/authenticate",
    {
      method: "POST",
      data: {
        email,
        password,
      },
    },
    false
  );
}

export function resetPasswordForEmail(email: Email): Promise<void> {
  return api(
    "/users/password_reset",
    {
      method: "POST",
      data: {
        user: {
          email,
        },
      },
    },
    false
  );
}
