import axios, { AxiosRequestConfig } from "axios";
import { Token } from "features/authentication/types";
import { camelizeKeys, decamelizeKeys } from "humps";

type ApiBody = {
  [key: string]: unknown;
};
function getBaseUrl(): string {
  const API_URL = process.env.REACT_APP_DKD_API_URL;
  if (!API_URL) {
    throw new Error("No environment variable REACT_APP_DKD_API_URL specified");
  }
  return API_URL;
}

function getAuthToken(): Token {
  const tokenKey = process.env.REACT_APP_DKD_AUTH_TOKEN_NAME;
  if (!tokenKey) {
    throw new Error(
      "No environment variable REACT_APP_DKD_AUTH_TOKEN_NAME specified"
    );
  }
  const token = localStorage.getItem(tokenKey);
  if (!token) {
    throw new Error("No token found in local storage");
  }

  return token;
}

type Endpoint = string;

export default function api<T>(
  endpoint: Endpoint,
  config: AxiosRequestConfig,
  authorization: boolean = true
): Promise<T> {
  return axios(endpoint, {
    ...config,
    baseURL: getBaseUrl(),
    timeout: 60_000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(authorization && { Authorization: getAuthToken() }),
    },
    transformRequest: (data: ApiBody) => {
      // convert camelCase to snake_case
      return JSON.stringify(decamelizeKeys(data));
    },
    transformResponse: (data) => {
      // convert snake_case to camelCase, if and only if the key is actually snake_case
      return camelizeKeys(JSON.parse(data), (key, convert) => {
        return /^[a-z_]+$/.test(key) ? convert(key) : key;
      });
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch(({ response, request, message }) => {
      if (response) {
        console.error(response.status, response.data);
      } else if (request) {
        console.error(request);
      } else {
        console.error(message);
      }
      return Promise.reject(response);
    });
}

export function get<T>(endpoint: Endpoint): Promise<T> {
  return api(endpoint, {
    method: "GET",
  });
}

export async function post<T>(endpoint: Endpoint, body: ApiBody): Promise<T> {
  return api(endpoint, {
    method: "POST",
    data: body,
  });
}

export function put<T>(endpoint: Endpoint, body: ApiBody): Promise<T> {
  return api(endpoint, {
    method: "PUT",
    data: body,
  });
}
