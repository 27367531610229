import Navigation, { NavigationPropsItem } from "components/common/Navigation";
import { getTeam } from "features/teams/actions";
import React, { useEffect } from "react";
import { useAppDispatch } from "store/hooks";

type Props = {
  year: number;
};

const createNavigationPropsItem = (year: number): NavigationPropsItem => {
  return {
    url: `/drifveri/${year}`,
    label: `Drifveriet ${year}`,
  };
};

const TeamsNavigation = ({ year }: Props) => {
  const dispatch = useAppDispatch();

  // this is a hack since Teams.js is not Typescript, and can't enforce the type properly
  // even though year is typed as a number here, it will be passed as a string
  const prev = parseInt(String(year)) - 1;
  const next = parseInt(String(year)) + 1;

  useEffect(() => {
    dispatch(getTeam(prev));
    dispatch(getTeam(next));
  }, [dispatch, next, prev]);

  return (
    <Navigation
      prev={createNavigationPropsItem(prev)}
      next={createNavigationPropsItem(next)}
    />
  );
};

export default TeamsNavigation;
