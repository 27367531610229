import Topbar from "components/header/Topbar";
import React from "react";
import { ThemeProvider } from "styled-components";
import css from "styles/layout.module.css";
import { modernTheme } from "styles/theme";

const Layout = ({ children }) => (
  <ThemeProvider theme={modernTheme}>
    <div className={css.page}>
      <Topbar />
      <main className={css.main}>{children}</main>
    </div>
  </ThemeProvider>
);

export default Layout;
